var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{staticClass:"mb-6",attrs:{"fixed-tabs":"","color":_vm.highlightColor}},[_c('v-tab',{staticClass:"tab-item",style:(_vm.styleObject)},[_c('span',[_vm._v("免密登录")])])],1),_c('div',{staticClass:"text-center pa-6",style:({
      color: _vm.highlightColor
    })},[_c('v-sheet',{staticClass:"rounded-circle",staticStyle:{"margin":"auto"},attrs:{"color":"white","elevation":"2","height":"120","width":"120"}},[_c('v-img',{staticClass:"rounded-circle",attrs:{"contain":"","width":"120","height":"120","src":_vm.avatarUrl ? _vm.avatarUrl : require('../../../assets/avatar.png')}})],1),(_vm.fido2Info && (_vm.fido2Info.nickname || _vm.fido2Info.login_name))?_c('h3',{staticClass:"my-4 white--text",staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(_vm.fido2Info.nickname || _vm.fido2Info.login_name)+" ")]):_vm._e(),_c('p',{staticClass:"text-body-1 white--text"},[_vm._v(" "+_vm._s(_vm.loginBtnStatus === "cancelOperate" ? "免密登录请求已取消" : "您的账号已绑定免密码登录")+" ")]),_c('v-row',{staticClass:"px-0 mt-2"},[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loginBtnStatus)?_c('v-btn',{staticClass:"py-5",style:({
            backgroundColor: _vm.highlightColor,
            color: '#fff',
            borderRadius: '8px',
            width: '100%',
            fontSize: '18px'
          }),attrs:{"loading":_vm.loginLoding,"height":"40"},on:{"click":function($event){$event.stopPropagation();return _vm.doFidoLogin($event)}}},[_vm._v(" 一键登录 ")]):_vm._e(),(_vm.loginBtnStatus === 'cancelOperate')?_c('v-btn',{staticClass:"py-5 #eee black--text",staticStyle:{"border-radius":"8px","width":"100%","font-size":"18px"},attrs:{"loading":_vm.loginLoding,"height":"40"},on:{"click":function($event){$event.stopPropagation();return _vm.doFidoLogin($event)}}},[_vm._v(" 再试一次 ")]):_vm._e(),(
            _vm.loginBtnStatus === 'deviceVerifying' ||
              _vm.loginBtnStatus === 'serviceVerifying'
          )?_c('v-btn',{staticClass:"py-5 #eee black--text",staticStyle:{"border-radius":"8px","width":"100%","font-size":"18px"},attrs:{"height":"40"}},[_c('v-btn',{staticClass:"load_btn",attrs:{"loading":_vm.loginLoding,"depressed":"","text":""}}),(_vm.loginBtnStatus === 'deviceVerifying')?_c('span',[_vm._v("等待设备验证")]):_vm._e(),(_vm.loginBtnStatus === 'serviceVerifying')?_c('span',[_vm._v("服务器验证中")]):_vm._e()],1):_vm._e()],1),(_vm.loginBtnStatus !== 'cancelOperate')?_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text text-caption",staticStyle:{"cursor":"pointer"},on:{"click":_vm.useOtherAccount}},[_vm._v("使用其他账号")])]):_c('v-col',{staticClass:"mt-2 text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"white--text text-body-1",staticStyle:{"cursor":"pointer"},on:{"click":_vm.useOtherAccount}},[_vm._v(" 使用其他账号 ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }