<template>
  <div>
    <v-tabs fixed-tabs class="mb-6" :color="highlightColor">
      <v-tab class="tab-item" :style="styleObject">
        <span>免密登录</span>
      </v-tab>
    </v-tabs>

    <div
      class="text-center pa-6"
      :style="{
        color: highlightColor
      }"
    >
      <v-sheet
        color="white"
        elevation="2"
        height="120"
        width="120"
        class="rounded-circle"
        style="margin:auto"
      >
        <v-img
          contain
          width="120"
          height="120"
          class="rounded-circle"
          :src="avatarUrl ? avatarUrl : require('../../../assets/avatar.png')"
        ></v-img>
      </v-sheet>
      <h3
        class="my-4 white--text"
        style="font-weight:700"
        v-if="fido2Info && (fido2Info.nickname || fido2Info.login_name)"
      >
        {{ fido2Info.nickname || fido2Info.login_name }}
      </h3>

      <p class="text-body-1 white--text">
        {{
          loginBtnStatus === "cancelOperate"
            ? "免密登录请求已取消"
            : "您的账号已绑定免密码登录"
        }}
      </p>
      <v-row class="px-0 mt-2">
        <v-col cols="12">
          <v-btn
            v-if="!loginBtnStatus"
            :loading="loginLoding"
            @click.stop="doFidoLogin"
            class="py-5"
            height="40"
            :style="{
              backgroundColor: highlightColor,
              color: '#fff',
              borderRadius: '8px',
              width: '100%',
              fontSize: '18px'
            }"
          >
            一键登录
          </v-btn>
          <v-btn
            v-if="loginBtnStatus === 'cancelOperate'"
            :loading="loginLoding"
            @click.stop="doFidoLogin"
            height="40"
            class="py-5 #eee black--text"
            style="border-radius:8px;width:100%;font-size:18px"
          >
            再试一次
          </v-btn>
          <v-btn
            v-if="
              loginBtnStatus === 'deviceVerifying' ||
                loginBtnStatus === 'serviceVerifying'
            "
            class="py-5 #eee black--text"
            style="border-radius:8px;width:100%;font-size:18px"
            height="40"
          >
            <v-btn
              :loading="loginLoding"
              class="load_btn"
              depressed
              text
            ></v-btn>
            <span v-if="loginBtnStatus === 'deviceVerifying'"
              >等待设备验证</span
            >
            <span v-if="loginBtnStatus === 'serviceVerifying'"
              >服务器验证中</span
            >
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          class="text-right"
          v-if="loginBtnStatus !== 'cancelOperate'"
        >
          <span
            class="white--text text-caption"
            style="cursor:pointer"
            @click="useOtherAccount"
            >使用其他账号</span
          >
        </v-col>
        <v-col cols="12" class="mt-2 text-center" v-else>
          <span
            class="white--text text-body-1"
            style="cursor:pointer"
            @click="useOtherAccount"
          >
            使用其他账号
          </span>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import { urlsafe_b64decode_array } from "@/util/jwes";
import { bufferEncode } from "@/util/fido_util";

export default {
  name: "PcFidoCard",
  props: ["highlightColor", "styleObject", "fido2Info", "fidoData"],
  data() {
    return {
      loginLoding: false,
      loginBtnStatus: null,
      avatarUrl: null
    };
  },
  created() {
    let accountInfo = JSON.parse(localStorage.getItem("last-account"));
    if (accountInfo && accountInfo.avatar_url) {
      this.avatarUrl = accountInfo.avatar_url;
    }
  },
  methods: {
    doFidoLogin() {
      this.fidoLogin(this.fidoData.id);
    },
    fidoLogin(id) {
      this.loginLoding = true;
      this.loginBtnStatus = "deviceVerifying";
      let accountInfo = JSON.parse(localStorage.getItem("last-account"));
      return this.$http
        .get(`api/source/${id}/start?account_id=${accountInfo.name}`)
        .delegateTo(api_request)
        .then(({ content: { challenge, credential_ids } }) => {
          let allowCredentials = credential_ids.map(item => {
            let credentialData = {};
            credentialData.id = urlsafe_b64decode_array(item);
            credentialData.type = "public-key";
            return credentialData;
          });

          let publicKey = {
            challenge: urlsafe_b64decode_array(challenge),
            timeout: 60000,
            allowCredentials,
            userVerification: "preferred"
          };
          navigator.credentials
            .get({ publicKey })
            .then(credential => {
              this.loginCredential(credential, accountInfo.name, id);
            })
            .catch(() => {
              this.loginLoding = false;
              this.loginBtnStatus = "cancelOperate";
            })
            .delegateTo(this.$snackbar.delegateError);
        });
    },
    loginCredential(credential, account_id, id) {
      this.loginBtnStatus = "serviceVerifying";
      let authData = new Uint8Array(credential.response.authenticatorData);
      let clientDataJSON = new Uint8Array(credential.response.clientDataJSON);
      let sig = new Uint8Array(credential.response.signature);
      let userHandle = new Uint8Array(credential.response.userHandle);
      let payload = {
        credential_id: credential.id,
        account_id,
        authenticator_data: bufferEncode(authData),
        client_data: bufferEncode(clientDataJSON),
        signature: bufferEncode(sig),
        user_handle: bufferEncode(userHandle)
      };

      return this.$http
        .post(`/api/source/${id}/finish`, payload)
        .delegateTo(api_request)
        .then(data => {
          this.$store.commit("auth_success", data);
          if (data["return_url"]) {
            window.location = data["return_url"];
          } else {
            this.$router.push({ path: "/" });
          }
          return "登录成功";
        })
        .catch(({ code }) => {
          this.loginLoding = false;
          this.loginBtnStatus = null;
          throw `登录失败：${this.$t("api." + code)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    useOtherAccount() {
      this.$emit("useOtherEvent");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .load_btn.v-btn:not(.v-btn--round).v-size--default {
  min-width: 28px !important;
}
</style>
