<template>
  <!-- 第三页确认绑定 -->
  <div
    class="blue-grey lighten-5 px-6 py-9 d-inline-flex flex-column align-center justify-space-between unselect"
    :style="{
      width: '100%',
      height: '100vh'
    }"
  >
    <!-- 微信账号绑定 -->

    <div class="d-flex flex-column align-center mt-6">
      <v-sheet
        color="white"
        elevation="2"
        height="120"
        width="120"
        class="rounded-circle"
      >
        <v-img
          contain
          width="120"
          height="120"
          class="rounded-circle"
          :src="headimgurl ? headimgurl : require('@/assets/avatar.png')"
        ></v-img>
      </v-sheet>
      <div class="text-h6 mt-2 text-center mt-2">
        <div>{{ nickname }}</div>
        <div class="mt-9">
          <div>你即将绑定此微信账号</div>
          <div>后续可以使用此微信账号登录</div>
        </div>
      </div>
    </div>

    <div class=" text-body-1 mt-12 d-flex flex-column align-center">
      <v-btn
        @click="doBindEvent"
        color="success"
        min-width="260"
        min-height="50"
        class="text-h6"
      >
        绑定并继续
      </v-btn>
      <div
        @click="cancelBindEvent"
        class="mt-8"
        style="color:#707B9A;font-weight:600"
      >
        取消绑定
      </div>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";

export default {
  name: "WechatBind",
  props: ["headimgurl", "nickname"],
  data() {
    return {
      highlightColor: "",
      fontColor: ""
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 绑定并继续
    doBindEvent() {
      this.$emit("doBind");
    },
    // 取消绑定
    cancelBindEvent() {
      this.$emit("cancelBind");
    },
    fetchData() {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(config => {
          let {
            loginFrame: { loginFontColor, loginHighLight }
          } = config;

          this.highlightColor = loginHighLight || "#FF0000";
          this.fontColor = loginFontColor || "#000000";
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    }
  }
};
</script>
