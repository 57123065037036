<template>
  <div
    v-if="loginPageCtrl === 'show'"
    class="white unselect overflow-hidden"
    style="width:100%;height:100%;"
  >
    <!-- 第一页登录操作页 -->
    <div v-if="showError === 'no'">
      <div
        class="white lighten-2 pb-6 d-flex flex-column flex-lg-grow-0 flex-md-grow-0"
        style="width:100%;height:100%;max-width:400px;margin:0 auto;"
        v-if="firstpage"
      >
        <!-- 温馨提示 -->
        <Notice
          v-if="showUseInstruction && noticeDisplay === 'dynamic'"
          :fontColor="fontColor"
          :showUseInstruction="showUseInstruction"
          :noticeDisplay="noticeDisplay"
          :useInstructionTitle="useInstructionTitle"
          :useInstruction="useInstruction"
        ></Notice>

        <!-- logo -->
        <Logo
          :system_name="system_name"
          :sonData="sonData"
          :showUseInstruction="showUseInstruction"
          :noticeDisplay="noticeDisplay"
        ></Logo>

        <!-- 静态展示温馨提示 -->
        <StaticNotice
          v-if="showUseInstruction && noticeDisplay === 'staticState'"
          :fontColor="fontColor"
          :showUseInstruction="showUseInstruction"
          :noticeDisplay="noticeDisplay"
          :useInstructionTitle="useInstructionTitle"
          :useInstruction="useInstruction"
        ></StaticNotice>

        <!-- 登录方式区域显示 -->
        <div class="px-8 mt-2">
          <!-- 输入框登录 -->
          <LoginModeTitle
            :curTabItem="curTabItem"
            :showLoginName="showLoginName"
          ></LoginModeTitle>

          <!-- 登录方式展示 -->
          <component
            :is="loginCards[curTabItem.type]"
            :sonData="sonData"
            :curTabItem="curTabItem"
            :fromType="fromType"
            @showAccountEvent="showAccountEvent($event)"
          ></component>
        </div>
        <!-- 其他登录方式 -->
        <OtherLink
          :tabLists="tabLists"
          @curTabEvent="curTabEvent($event)"
        ></OtherLink>
      </div>
    </div>

    <!-- 账号绑定部分 -->
    <WechatBind
      v-if="showError === 'binding'"
      :headimgurl="headimgurl"
      :nickname="nickname"
      :sonData="sonData"
      @doBind="doBind"
      @cancelBind="cancelBind"
    ></WechatBind>

    <!-- 温馨提示使用指定环境打开 -->
    <ErrorMobileLogin
      v-if="showError === 'yes'"
      :errorType="errorType"
    ></ErrorMobileLogin>
    <Snackbar />

    <ConfirmBox ref="confirm" />
  </div>
</template>

<script>
import Cookie from "js-cookie";
import { loginCards } from "../utils/base";
import Snackbar from "@/components/Snackbar";
import ConfirmBox from "@/components/ConfirmBox";
import ErrorMobileLogin from "../mobileComp/ErrorMobileLogin";
import WechatBind from "../mobileComp/WechatBind";
import FnuLdapCard from "../mobileComp/FnuLdapCard";
import Notice from "../mobileComp/Notice";
import StaticNotice from "../mobileComp/StaticNotice";
import Logo from "../mobileComp/Logo";
import LoginModeTitle from "../mobileComp/LoginModeTitle";
import PasswordCard from "../mobileComp/PasswordCard";
import BjdCard from "../mobileComp/BjdCard";
import Outh2EmbedCard from "../mobileComp/Outh2EmbedCard";
import SmsCard from "../mobileComp/SmsCard";
import OtherLink from "../mobileComp/OtherLink";
import { api_request } from "@/util/network";
import { randomSessionId, externalBrowser, isSourceId } from "@/util/util";
import { isSetMobile } from "../utils/dealMobile";
import {
  filterMethod,
  filterTabLinks,
  filterExtr,
  existSpecialType,
  isExistSpecialType,
  filterTypeItem,
  filterIdentitySource
} from "../utils/filterData";

export default {
  name: "MobileLogin",
  inject: ["reload"],
  data() {
    let isFull = value => {
      return value.trim().length > 0;
    };
    let isRule = value => {
      let reg = /^\d{6}$/;
      return value && reg.test(value);
    };
    return {
      system_name: null,
      loginPageCtrl: null,
      rules: {
        isFull: [v => isFull(v) || "此项为必填项"],
        isRule: [v => isRule(v) || "请输入正确的验证码"]
      },

      firstpage: true,
      tabLists: [],
      curTabItem: {},
      headimgurl: null,
      nickname: "AlphaGo",
      fullname: "",
      allTabList: [],
      showError: null,
      errorType: "",
      getTabs: [],
      otherLinks: [],
      sonData: {},
      loginCards,
      fromType: "",
      loginAccountInfo: {},
      showLoginName: null,
      inheritPc: "",
      showUseInstruction: "",
      noticeDisplay: "",
      useInstructionTitle: "",
      useInstruction: "",
      fontColor: "",
      wechatMethod: "",
      wechatEnterpriseMethod: "",
      wechatEnterprisePrivateMethod: "",
      dingTalkMethod: "",
      larkMethod: "",
      wechatSources: [],
      wechatEnterpriseSources: [],
      wechatEnterprisePrivateSources: [],
      dingTalkSources: [],
      larkSources: [],
      getQrcode: ""
    };
  },
  async created() {
    await this.getTabLists();
    await this.getSchoolName();
    let needBind = sessionStorage.getItem("needBind");
    let logoutStatus = sessionStorage.getItem("logoutStatus");
    if (needBind && logoutStatus) {
      setTimeout(() => {
        alert("当前微信未绑定本地账户，请先进行绑定");
      }, 0);
      await this.fetchData(true);
      let sources = await filterIdentitySource(this.allTabList);
      this.curTabItem = sources[0];
      this.loginPageCtrl = "show";
      this.showError = "no";
      this.tabLists = sources.filter(item => item !== this.curTabItem);
      this.fromType = "WECHAT_RZKC";
    } else {
      await this.fetchData();
    }
    console.log(this.curTabItem);
  },
  methods: {
    // 控制绑定账户展示
    showAccountEvent(data) {
      this.showError = "binding";
      this.loginAccountInfo = data.accountInfo;
      let finishData = JSON.parse(localStorage.getItem("finishData"));
      if (finishData) {
        let { headimgurl, nickname } = finishData;
        this.headimgurl = headimgurl;
        this.nickname = nickname;
      }
    },
    curTabEvent(curItem) {
      if (curItem.link) {
        location.href = curItem.link;
        return;
      } else {
        this.firstpage = true;
        this.curTabItem = curItem;
        let loginMehods = filterExtr(this.allTabList);
        this.tabLists = loginMehods.filter(item => item !== this.curTabItem);

        if (this.$refs.form) {
          this.$refs.form.resetValidation();
          this.userName = "";
          this.passWord = "";
        }
      }
    },
    getTabLists() {
      return this.$http
        .get(`api/source/login_methods`)
        .delegateTo(api_request)
        .then(async res => {
          let tabLists = res.credential.concat(res.external);
          this.allTabList = tabLists;
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchData(isBind = null) {
      return this.$http
        .get(`api/settings/mobile_login`)
        .delegateTo(api_request)
        .then(async config => {
          if (Object.keys(config).length === 0) {
            return;
          }
          this.sonData = config;
          // 判断移动端是否配置
          let existSet = isSetMobile(config);
          //已配置
          if (existSet) {
            this.MloginRender(isBind, config, "mobile");
          } else {
            this.fetchPcData(isBind);
          }
        })
        .catch(({ code, message }) => {
          throw `登录失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    async MloginRender(isBind, config, fromApi) {
      let fromDevice;
      if (fromApi === "mobile") {
        let {
          tabs,
          inheritPc,
          otherLinks,
          wechatMethod,
          wechatEnterpriseMethod,
          wechatEnterprisePrivateMethod,
          dingTalkMethod,
          larkMethod,
          loginStyle,
          showLoginName,
          showUseInstruction,
          noticeDisplay,
          useInstructionTitle,
          useInstruction,
          loginFrame: { loginFontColor }
        } = config;
        this.wechatMethod = wechatMethod;
        this.wechatEnterpriseMethod = wechatEnterpriseMethod;
        this.wechatEnterprisePrivateMethod = wechatEnterprisePrivateMethod;
        this.dingTalkMethod = dingTalkMethod;
        this.larkMethod = larkMethod;
        if (inheritPc) {
          this.fetchPcData(isBind, inheritPc);
        } else {
          this.showUseInstruction = showUseInstruction;
          this.noticeDisplay = noticeDisplay;
          this.useInstructionTitle = useInstructionTitle;
          this.useInstruction = useInstruction;
        }
        this.getTabs = tabs;
        this.otherLinks = otherLinks;
        this.showLoginName = showLoginName ? true : false;
        this.fontColor = loginFontColor;
        this.customStyle(loginStyle);
        await this.filterLoginMethods();
        fromDevice = "mobile";
      } else if (fromApi === "pc") {
        let {
          tabs,
          otherLinks,
          qrcode,
          showUseInstruction,
          noticeDisplay,
          useInstructionTitle,
          useInstruction,
          loginStyle,
          loginFrame: { loginFontColor }
        } = config;
        this.getTabs = tabs;
        this.otherLinks = otherLinks;
        this.showLoginName = true;
        this.getQrcode = qrcode;
        this.fontColor = loginFontColor;
        this.noticeDisplay = noticeDisplay ? noticeDisplay : "dynamic";
        this.showUseInstruction = showUseInstruction;
        this.useInstructionTitle = useInstructionTitle;
        this.useInstruction = useInstruction;
        this.customStyle(loginStyle);
        await this.pcloginMethods();
        fromDevice = "pc";
      }

      if (isBind) {
        return this.tabLists;
      }
      this.isExistSourceId(fromDevice);
    },
    fetchPcData(isBind, inheritPc = false) {
      return this.$http
        .get(`api/settings/login`)
        .delegateTo(api_request)
        .then(config => {
          if (inheritPc) {
            let {
              showUseInstruction,
              noticeDisplay,
              useInstructionTitle,
              useInstruction
            } = config;
            this.noticeDisplay = noticeDisplay ? noticeDisplay : "dynamic";
            this.showUseInstruction = showUseInstruction;
            this.useInstructionTitle = useInstructionTitle;
            this.useInstruction = useInstruction;
          } else {
            this.MloginRender(isBind, config, "pc");
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    filterLoginMethods() {
      let tabSources = filterTabLinks(this.allTabList, this.getTabs);
      let linkSources = filterTabLinks(this.allTabList, this.otherLinks);
      this.wechatSources = filterMethod(this.allTabList, this.wechatMethod);
      this.wechatEnterpriseSources = filterMethod(
        this.allTabList,
        this.wechatEnterpriseMethod
      );
      this.wechatEnterprisePrivateSources = filterMethod(
        this.allTabList,
        this.wechatEnterprisePrivateMethod
      );
      this.dingTalkSources = filterMethod(this.allTabList, this.dingTalkMethod);
      this.larkSources = filterMethod(this.allTabList, this.larkMethod);
      this.allTabList = tabSources.concat(
        linkSources,
        this.wechatSources,
        this.wechatEnterpriseSources,
        this.wechatEnterprisePrivateSources,
        this.dingTalkSources,
        this.larkSources
      );
    },
    pcloginMethods() {
      let tabSources = filterTabLinks(this.allTabList, this.getTabs);
      let linkSources = filterTabLinks(this.allTabList, this.otherLinks);
      let otherSources = filterMethod(this.allTabList, this.getQrcode);
      this.allTabList = tabSources.concat(linkSources, otherSources);
    },
    useOtherLogin() {
      this.firstpage = true;
      this.reload();
    },
    async cancelLogin() {
      sessionStorage.clear();
      localStorage.clear();
      this.firstpage = true;
      this.reload();
    },
    async getSchoolName() {
      return this.$http
        .get(`api/system_config`)
        .delegateTo(api_request)
        .then(({ system_name }) => {
          this.system_name = system_name ? system_name : "瑞智康诚WebEXP";
        })
        .catch(({ code, message }) => {
          throw `获取失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    filterItem(id) {
      let curItem = this.allTabList.find(item => item.id === id);
      return curItem;
    },
    curBrower(ua, source, fromDevice) {
      // 检测当前浏览器环境
      if (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == "wxwork"
      ) {
        //企业微信上下文
        if (source && source.type === "WECHAT_ENTERPRISE") {
          let wxEnterprise = source;
          this.curBrowserStart(wxEnterprise);
        } else if (source && source.type === "WECHAT_ENTERPRISE_PRIVATE") {
          let wxEnterprise = source;
          this.curBrowserStart(wxEnterprise);
        } else {
          let wxEnterprise;
          if (fromDevice === "mobile") {
            wxEnterprise = filterTypeItem(
              this.wechatEnterpriseSources,
              "WECHAT_ENTERPRISE"
            );
          } else if (fromDevice === "pc") {
            wxEnterprise = filterTypeItem(this.allTabList, "WECHAT_ENTERPRISE");
          }

          if (!wxEnterprise) {
            if (fromDevice === "mobile") {
              wxEnterprise = filterTypeItem(
                this.wechatEnterpriseSources,
                "WECHAT_ENTERPRISE_PRIVATE"
              );
            } else if (fromDevice === "pc") {
              wxEnterprise = filterTypeItem(
                this.allTabList,
                "WECHAT_ENTERPRISE_PRIVATE"
              );
            }
          }

          if (wxEnterprise) {
            this.curBrowserStart(wxEnterprise);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/micromessenger/i) == "micromessenger") {
        // 微信上下文
        if (
          source &&
          (source.type === "WECHAT_WEBSITE" ||
            source.type === "WECHAT_OFFICIAL" ||
            source.type === "WECHAT_RZKC" ||
            source.type === "WECHAT_ENTERPRISE" ||
            source.type === "WECHAT_ENTERPRISE_PRIVATE")
        ) {
          let curWX = this.filterItem(source.id);
          this.curBrowserStart(curWX);
        } else {
          let wxwebsite;
          if (fromDevice === "mobile") {
            wxwebsite = filterTypeItem(this.wechatSources, "WECHAT_WEBSITE");
          } else if (fromDevice === "pc") {
            wxwebsite = filterTypeItem(this.allTabList, "WECHAT_WEBSITE");
          }

          let wxofficial;
          if (fromDevice === "mobile") {
            wxofficial = filterTypeItem(this.wechatSources, "WECHAT_OFFICIAL");
          } else if (fromDevice === "pc") {
            wxofficial = filterTypeItem(this.allTabList, "WECHAT_OFFICIAL");
          }

          let wxRZKC;
          if (fromDevice === "mobile") {
            wxRZKC = filterTypeItem(this.wechatSources, "WECHAT_RZKC");
          } else if (fromDevice === "pc") {
            wxRZKC = filterTypeItem(this.allTabList, "WECHAT_RZKC");
          }

          let wxPrivateEnterprise;
          if (fromDevice === "mobile") {
            wxPrivateEnterprise = filterTypeItem(
              this.wechatSources,
              "WECHAT_ENTERPRISE_PRIVATE"
            );
          } else if (fromDevice === "pc") {
            wxPrivateEnterprise = filterTypeItem(
              this.allTabList,
              "WECHAT_ENTERPRISE_PRIVATE"
            );
          }

          let wxEnterprise;
          if (fromDevice === "mobile") {
            wxEnterprise = filterTypeItem(
              this.wechatSources,
              "WECHAT_ENTERPRISE"
            );
          } else if (fromDevice === "pc") {
            wxEnterprise = filterTypeItem(this.allTabList, "WECHAT_ENTERPRISE");
          }

          if (wxwebsite) {
            this.curBrowserStart(wxwebsite);
          } else if (wxofficial) {
            this.curBrowserStart(wxofficial);
          } else if (wxRZKC) {
            this.curBrowserStart(wxRZKC);
          } else if (wxPrivateEnterprise) {
            this.curBrowserStart(wxPrivateEnterprise);
          } else if (wxEnterprise) {
            this.curBrowserStart(wxEnterprise);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/dingtalk/i) == "dingtalk") {
        // 钉钉上下文
        if (source && source.type === "DING_TALK") {
          let dingTalk = source;
          this.curBrowserStart(dingTalk);
        } else {
          let dingTalk;
          if (fromDevice === "mobile") {
            dingTalk = filterTypeItem(this.dingTalkSources, "DING_TALK");
          } else if (fromDevice === "pc") {
            dingTalk = filterTypeItem(this.allTabList, "DING_TALK");
          }

          if (dingTalk) {
            this.curBrowserStart(dingTalk);
          } else {
            this.defaultRenderLogin();
          }
        }
      } else if (ua.match(/lark/i) == "lark") {
        // 飞书上下文
        if (source && source.type === "LARK") {
          let linkUrl = encodeURI(`api/source/${source.id}/start`);
          window.location.href = linkUrl;
        } else {
          let lark;
          if (fromDevice === "mobile") {
            lark = filterTypeItem(this.larkSources, "LARK");
          } else if (fromDevice === "pc") {
            lark = filterTypeItem(this.allTabList, "LARK");
          }

          if (lark) {
            let linkUrl = encodeURI(`api/source/${lark.id}/start`);
            window.location.href = linkUrl;
          } else {
            this.defaultRenderLogin();
          }
        }
      }
    },
    async curBrowserStart(curRunItem) {
      if (curRunItem.type === "WECHAT_RZKC") {
        let session = await randomSessionId();
        let millisecond = new Date().getTime();
        let expiresTime = new Date(millisecond + 60 * 1000 * 5);
        Cookie.set("session_id", session, {
          expires: expiresTime
        });

        this.$router.push({
          path: `/wechat/authorize`,
          query: {
            session: session
          }
        });
      } else {
        if (
          (curRunItem.type === "WECHAT_ENTERPRISE" ||
            curRunItem.type === "WECHAT_ENTERPRISE_PRIVATE") &&
          curRunItem.wechat_qrcode
        ) {
          this.doStart(curRunItem.id, true);
        } else {
          this.doStart(curRunItem.id);
        }
      }
    },
    doStart(id, wechat_qrcode = false) {
      let URL = wechat_qrcode
        ? `api/source/${id}/start?wechat_qrcode=true`
        : `api/source/${id}/start`;
      return this.$http
        .get(URL)
        .delegateTo(api_request)
        .then(startRes => {
          if (wechat_qrcode) {
            let originName = location.origin;
            let {
              content: { appid, state }
            } = startRes;

            let hrefUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${originName}/api/source/${id}/finish&response_type=code&scope=snsapi_base&state=${state}#wechat_redirect`;

            window.location.href = hrefUrl.replace(
              /#/g,
              `&v=${new Date().getTime()}#`
            );
          } else {
            let linkUrl = startRes.content.replace(
              /#/g,
              `&v=${new Date().getTime()}#`
            );

            window.location.href = linkUrl;
          }
        })
        .catch(({ code, message }) => {
          throw `获取数据失败：${this.$t("api." + code)}, 额外信息: ${"api." +
            JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    defaultRenderLogin() {
      this.loginPageCtrl = "show";
      this.showError = "no";
      let loginMehods = filterExtr(this.allTabList);
      this.curTabItem = loginMehods[0];
      this.tabLists = loginMehods.filter(item => item !== this.curTabItem);
    },
    // 确认绑定并继续
    doBind(flag) {
      let bindData = JSON.parse(localStorage.getItem("finishData"));
      let payload = {};

      if (bindData) {
        let { identity_token, session_id } = bindData;
        if (!flag) {
          payload = {
            session_id,
            identity_token
          };
        } else {
          payload = {
            session_id,
            identity_token,
            ignore_bound: true
          };
        }
      }
      return this.$http
        .post(`api/auth/bind_account`, payload)
        .delegateTo(api_request)
        .then(data => {
          if (data) {
            window.location = data["return_url"];
          } else {
            localStorage.clear();
            this.$store.commit("auth_success", this.loginAccountInfo);
            if (this.loginAccountInfo["return_url"]) {
              window.location = this.loginAccountInfo["return_url"];
            } else {
              this.$router.push({ path: "/" });
            }
            return "绑定成功";
          }
        })
        .catch(({ code, message }) => {
          if (code === "AccountAlreadyBoundSource") {
            this.isChangeBind();
          } else {
            localStorage.removeItem("session_id");
            localStorage.removeItem("bindData");
            throw `绑定失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
              "api." + typeof message === "string"
                ? message
                : JSON.stringify(message)
            )}`;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    // 该用户已绑定其他微信号，是否换绑
    isChangeBind() {
      this.$refs.confirm
        .showConfirm({
          contentText: `该用户已绑定其他微信号，是否重新绑定为『${this.nickname}』？`,
          confirmText: "确认换绑",
          cancelText: "取消绑定并登出",
          callback: () => {
            this.doBind(true);
            return new Promise(resolve => resolve());
          }
        })
        .catch(() => {
          this.cancelBind();
        });
    },
    // 取消绑定
    async cancelBind() {
      await this.logout();
    },
    logout() {
      this.$http
        .get(`api/logout`)
        .delegateTo(api_request)
        .then(() => {
          setTimeout(async () => {
            localStorage.clear();
            sessionStorage.clear();
            await this.$store.commit("logout");
            window.WeixinJSBridge.invoke("closeWindow");
          }, 120);
        })
        .catch(({ code, message }) => {
          throw `登出失败：${this.$t("api." + code)}, 额外信息: ${this.$t(
            "api." + typeof message === "string"
              ? message
              : JSON.stringify(message)
          )}`;
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    isExistSourceId(fromDevice) {
      let source_id = isSourceId();
      let source = this.filterItem(source_id);

      // 检测是否存在source_id
      //1.存在source_id
      if (source) {
        if (source.link) {
          location.href = source.link;
        } else {
          //移动端浏览器环境
          if (!externalBrowser()) {
            if (existSpecialType(source)) {
              if (
                source.type === "WECHAT_WEBSITE" ||
                source.type === "WECHAT_OFFICIAL" ||
                source.type === "WECHAT_RZKC" ||
                source.type === "WECHAT_ENTERPRISE" ||
                source.type === "WECHAT_ENTERPRISE_PRIVATE" ||
                source.type === "DING_TALK" ||
                source.type === "LARK"
                // source.type === "BJD_AUTH"
              ) {
                this.loginPageCtrl = "show";
                this.showError = "yes";
                this.errorType = source.type;
              } else {
                this.defaultRenderLogin();
              }
            } else {
              this.loginPageCtrl = "show";
              this.showError = "no";
              this.curTabItem = source;
            }
          } else if (externalBrowser()) {
            //移动端存在上下文环境
            if (existSpecialType(source)) {
              this.loginPageCtrl = null;
              let ua = window.navigator.userAgent.toLowerCase();
              this.curBrower(ua, source, fromDevice);
            } else {
              this.loginPageCtrl = "show";
              this.showError = "no";
              this.curTabItem = source;
            }
          }
        }
      } else if (externalBrowser()) {
        if (isExistSpecialType(this.allTabList)) {
          let ua = window.navigator.userAgent.toLowerCase();
          this.curBrower(ua, null, fromDevice);
        } else {
          this.defaultRenderLogin();
        }
      } else {
        this.defaultRenderLogin();
      }
    },
    customStyle(loginStyle) {
      if (loginStyle) {
        let styleText = loginStyle;
        let curstyle = document.createElement("style");
        curstyle.innerText = styleText;
        document.getElementsByTagName("body")[0].appendChild(curstyle);
      }
    }
  },
  components: {
    Snackbar,
    ErrorMobileLogin,
    WechatBind,
    FnuLdapCard,
    Notice,
    StaticNotice,
    Logo,
    LoginModeTitle,
    PasswordCard,
    SmsCard,
    OtherLink,
    ConfirmBox,
    Outh2EmbedCard,
    BjdCard
  }
};
</script>

<style lang="less" scoped>
/deep/ .v-sheet.v-card {
  border-radius: 3px !important;
}

/deep/ .v-input__slot {
  padding: 6px 12px !important;
  min-height: 48px !important;
}

/deep/ .v-input__append-inner {
  margin-top: 8px !important;
}

/deep/ .v-label {
  top: 10px !important;
}

.v-application {
  font-family: system-ui !important;
}
</style>
