<template>
  <v-card flat>
    <v-card-text>
      <v-form
        :model="smsForm"
        ref="smsForm"
        class="py-4"
        @submit.native.prevent
      >
        <v-form
          :model="smsCodeForm"
          ref="smsCodeForm"
          style="margin-bottom:12px;"
          @submit.native.prevent
        >
          <v-row>
            <v-col cols="12" class="px-0">
              <v-text-field
                solo
                label="请输入用户名或手机号"
                prepend-inner-icon="mdi-cellphone"
                v-model="userNameSms"
                required
                :rules="rules.isFull"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="showCaptcha">
            <v-col class="px-0" cols="12" style="position: relative">
              <v-text-field
                solo
                label="请输入图形验证码"
                prepend-inner-icon="mdi-check-circle-outline"
                v-model="verify_code"
                required
                :rules="rules.isFull"
              ></v-text-field>
              <img
                style="position: absolute; top: 4px; right: 4px"
                :src="captcha_image"
                alt=""
              />
            </v-col>
          </v-row>
        </v-form>
        <v-row>
          <v-col cols="12" class="px-0" style="position: relative">
            <v-text-field
              solo
              :label="verifycodePlaceholder"
              prepend-inner-icon="mdi-lock-outline"
              v-model="smsCode"
              required
              :rules="rules.isRule"
              maxlength="6"
              @keyup.enter="submitSMS(curItem)"
            ></v-text-field>
            <v-btn
              v-if="showCaptcha"
              elevation="0"
              style="position: absolute; top: 6px; right: 6px"
              :disabled="smsDisabled"
              @click="redoStart"
            >
              {{
                allTime === 60 || allTime === 0 ? "获取验证码" : allTime + "s"
              }}
            </v-btn>

            <v-btn
              v-else
              elevation="0"
              style="position: absolute; top: 6px; right: 6px"
              :disabled="smsDisabled"
              @click="sendCode"
            >
              {{
                allTime === 60 || allTime === 0 ? "获取验证码" : allTime + "s"
              }}
            </v-btn>
          </v-col>
        </v-row>

        <div
          class="d-flex justify-end mb-2"
          style="cursor: pointer;position:relative"
        >
          <div>
            <span
              v-if="showUseInstruction"
              class="text-caption unselect"
              @click="noticeEvent"
              :style="{ color: fontColor }"
            >
              {{ noticeTitle }}
            </span>
          </div>
          <div>
            <span class="text-caption" v-if="0"> 忘记密码 </span>
          </div>
        </div>
        <v-row class="mt-2">
          <v-col cols="12" class="px-0">
            <v-btn
              class="py-5"
              height="40"
              @click="submitSMS(curItem)"
              :style="{
                backgroundColor: highlightColor,
                color: fontColor,
                borderRadius: '8px',
                width: '100%',
                fontSize: '18px'
              }"
            >
              {{ loginSubmit }}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { api_request } from "@/util/network";
import { encryptPassword } from "@/util/jwes";
import { saveLastAccount } from "@/util/fido_util";

export default {
  name: "PcBjdSmsCard",
  props: ["sonData", "curId", "curItem", "fromType"],
  data() {
    let isFull = value => {
      let isfull = value ? true : false;
      return isfull;
    };
    let isTruePhone = value => {
      let reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      return reg.test(value);
    };
    let isRule = value => {
      let reg = /^\d{4}$/;
      let isrule = reg.test(value);
      return isrule;
    };
    return {
      rules: {
        isFull: [v => isFull(v) || "此项为必填项"],
        isPhone: [v => isTruePhone(v) || "不符合手机号规则"],
        isRule: [v => isRule(v) || "请输入正确的验证码"]
      },
      smsForm: {},
      smsCodeForm: {},
      userNameSms: "",
      smsCode: "",
      loginSubmit: "",
      verifycodePlaceholder: "",
      noticeTitle: "",
      allTime: 60,
      highlightColor: "",
      fontColor: "",
      verify_code: "",
      showCaptcha: false,
      captcha_image: "",
      captcha_challenge: "",
      timer: "",
      smsDisabled: false
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    // 获取验证码
    sendCode() {
      if (this.$refs.smsCodeForm.validate()) {
        this.smsDisabled = true;
        if (this.smsDisabled) {
          this.timer = setInterval(() => {
            if (this.allTime > 1) {
              this.allTime = this.allTime - 1;
            } else {
              clearInterval(this.timer);
              this.allTime = 60;
              this.smsDisabled = false;
            }
          }, 1000);
          return this.$http
            .get(`api/source/${this.curId}/start?username=${this.userNameSms}`)
            .delegateTo(api_request)
            .then(data => {
              let {
                action: { mobile }
              } = data;
              return `已发送至${mobile}`;
            })
            .catch(({ code, message }) => {
              clearInterval(this.timer);
              this.allTime = 60;
              this.smsDisabled = false;
              if (code === "MobileNotFound") {
                throw "此用户无关联手机号";
              } else if (message === "too frequently") {
                throw `发送验证码过于频繁，请等待一分钟后再试`;
              } else {
                throw `发送验证码失败：${this.$t("api." + code)}`;
              }
            })
            .delegateTo(this.$snackbar.delegate);
        }
      }
    },
    // 图形验证码登录
    finishLogin(curItem) {
      if (this.$refs.smsForm.validate()) {
        let loginUserNameKey = curItem.fields[0];
        let loginPwsKey = curItem.fields[1];
        let credentials;
        credentials = {
          [loginUserNameKey]: this.userNameSms,
          [loginPwsKey]: this.smsCode,
          captcha_challenge: this.captcha_challenge,
          code: this.smsCode
        };

        if (credentials["password"] !== undefined) {
          credentials["password"] = encryptPassword(
            this.exchange_key,
            this.challenge,
            credentials["password"]
          );
        }

        this.$http
          .post(`api/source/${curItem.id}/finish`, credentials)
          .delegateTo(api_request)
          .then(data => {
            if (this.fromType === "WECHAT_RZKC") {
              this.$emit("showAccountEvent", {
                showAccount: true,
                accountInfo: data
              });
            } else {
              this.$store.commit("auth_success", data);
              saveLastAccount(data);
              if (data["return_url"]) {
                window.location = data["return_url"];
              } else {
                this.$router.push({ path: "/" });
              }
              return "登录成功";
            }
          })
          .catch(({ code }) => {
            throw `登录失败：${this.$t("api." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    redoStart() {
      if (this.$refs.smsCodeForm.validate()) {
        this.smsDisabled = true;
        if (this.smsDisabled) {
          let timer = setInterval(() => {
            if (this.allTime > 1) {
              this.allTime = this.allTime - 1;
            } else {
              clearInterval(timer);
              this.allTime = 60;
              this.smsDisabled = false;
            }
          }, 1000);
          return this.$http
            .get(
              `api/source/${this.curId}/start?login_name=${this.userNameSms}&captcha_challenge=${this.captcha_challenge}&captcha_code=${this.verify_code}`
            )
            .delegateTo(api_request)
            .then(({ exchange_key, challenge }) => {
              this.exchange_key = exchange_key;
              this.challenge = challenge;
            })
            .catch(({ code, message }) => {
              clearInterval(timer);
              this.allTime = 60;
              this.smsDisabled = false;
              if (message === "too frequently") {
                throw `发送验证码过于频繁，请等待一分钟后再试`;
              } else {
                throw `发送验证码失败：${this.$t("api.sms." + code)}`;
              }
            })
            .delegateTo(this.$snackbar.delegateError);
        }
      }
    },
    doStart(curItem, loginName, verificationCode) {
      if (this.$refs.smsForm.validate()) {
        return this.$http
          .get(`api/source/${this.curId}/start?login_name=${loginName}`)
          .delegateTo(api_request)
          .then(({ type, content: { captcha_challenge, captcha_image } }) => {
            if (
              type === "captcha_required" &&
              captcha_challenge &&
              captcha_image
            ) {
              this.showCaptcha = true;
              this.captcha_image = captcha_image;
              localStorage.setItem("captcha_image", captcha_image);
            } else {
              this.smsLogin(curItem, loginName, verificationCode);
            }
          })
          .catch(({ code }) => {
            throw `发送验证码失败：${this.$t("api.sms." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    submitSMS(curItem) {
      this.finishLogin(curItem);
    },
    // 短信登录
    smsLogin(curItem, loginName, verificationCode) {
      if (this.$refs.smsForm.validate()) {
        // 调取短信登录接口
        let loginUserNameKey = curItem.fields[0];
        let loginPwsKey = curItem.fields[1];
        let credentials = {
          [loginUserNameKey]: loginName,
          [loginPwsKey]: verificationCode,
          code: verificationCode
        };

        if (credentials["password"] !== undefined) {
          credentials["password"] = encryptPassword(
            this.exchange_key,
            this.challenge,
            credentials["password"]
          );
        }
        this.$http
          .post(`api/source/${curItem.id}/finish`, credentials)
          .delegateTo(api_request)
          .then(data => {
            if (this.fromType === "WECHAT_RZKC") {
              this.$emit("showAccountEvent", {
                showAccount: true,
                accountInfo: data
              });
            } else {
              this.$store.commit("auth_success", data);
              saveLastAccount(data);
              if (data["return_url"]) {
                window.location = data["return_url"];
              } else {
                this.$router.push({ path: "/" });
              }
              return "登录成功";
            }
          })
          .catch(({ code }) => {
            throw `登录失败：${this.$t("api." + code)}`;
          })
          .delegateTo(this.$snackbar.delegateError);
      }
    },
    noticeEvent() {
      this.$emit("noticeChange", true);
    },
    fetchData() {
      let {
        showUseInstruction,
        verifycodePlaceholder,
        loginSubmit,
        useInstructionTitle,
        loginFrame: { loginFontColor, loginHighLight }
      } = this.sonData;

      this.showUseInstruction = showUseInstruction;
      this.verifycodePlaceholder = verifycodePlaceholder
        ? verifycodePlaceholder
        : "输入验证码";
      this.loginSubmit = loginSubmit ? loginSubmit : "登录";
      this.noticeTitle = useInstructionTitle;
      this.highlightColor = loginHighLight;
      this.fontColor = loginFontColor;
    }
  },
  watch: {
    curItem() {
      this.fetchData();
    }
  }
};
</script>
